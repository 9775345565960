import React, { useContext, useEffect, useState } from "react"
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Badge, Media } from "reactstrap"
import axios from "axios"
import * as Icon from "react-feather"
import { UserContext } from './../../../context/context'
import { URL_IMG } from "../../../config"
import imgDefault from './../../../assets/img/logo/avatarDefault.png'
import { PURGE } from 'redux-persist';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar"
import API from '../../../providers/api'
import moment from 'moment'
import { toast, ToastContainer } from "react-toastify"

const NavbarUser = () => {
  const user = JSON.parse(sessionStorage.getItem('user'))
  const { activateAuth, isSocket } = useContext(UserContext)
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [listNotifications, setListNotifications] = useState([]);

  useEffect(()=>{
    listNotificationsNav()
  },[])

  useEffect(() => {
    isSocket.onopen = () => {
      console.log('WebSocket Connected');
    }
    isSocket.onmessage = (e) => {
      const message = JSON.parse(e.data);
      setMessages([message, ...messages]);
      console.log(message.success, "message")
      if (message.success == true){
        toast.success(message.message)
        listNotificationsNav()
        notifyMe("Notification CRM", { "body": message.message, "icon": "https://www.pngrepo.com/png/154861/512/notification.png" });
      }
    }

    return () => {
      console.log("unmounted component")
      isSocket.onclose = () => {
        console.log('WebSocket Disconnected');
      }
    }
  }, [isSocket.onmessage, isSocket.onopen, isSocket.onclose, messages]);

  const listNotificationsNav = () => {
    API.listNotificationLimit(user.id)
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
          setTotalNotifications(dataJson.total)
          setListNotifications(dataJson.data)
        }else{
          setTotalNotifications(0)
          setListNotifications([])
        }
    })
  }

  const notifyMe = (texto, obj) => {
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    }

    else if (Notification.permission === "granted") {
        var notification = new Notification(texto, obj);
        setTimeout(notification.close.bind(notification), 5000);
    }
    else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
            if (permission === "granted") {
                var notification = new Notification(texto, obj);
                setTimeout(notification.close.bind(notification), 5000);
            }
        });
    }
  }

  const updateViewNotification = (id) =>{
    API.updateViewNotification(id)
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
          listNotificationsNav()
        }
    })
  }

  const renderNotificationItems = listNotifications.map(item => {
    return (
      <div className="d-flex justify-content-between" key={item.id}>
        <Media className="d-flex align-items-start" onClick={e => {
                  e.stopPropagation()
                  updateViewNotification(item.id)
                }}>
          <Media left href="#">
            {
              item.module == "Leads Dispositions" ?
                <Icon.PlusSquare className="font-medium-5 primary" size={21} />
                : item.module == "Emails" ?
                    <Icon.MessageCircle className="font-medium-5 danger" size={21} />
                : item.module == "Sales" ?
                    <Icon.Clipboard className="font-medium-5 warning" size={21} />
                :
                  <Icon.CheckCircle className="font-medium-5 dark" size={21} />
            }
          </Media>
          <Media body>
            <Media heading className="primary media-heading" tag="h6">
                {item.module}
              </Media>
              <p className="notification-text">
                {item.observation}
              </p>
            </Media>
            <small>
              <time
                className="media-meta"
                dateTime={item.datecreate}
              >
                {moment(item.datecreate).format('MM/DD/YYYY HH:mm:ss')}
              </time>
            </small>
        </Media>
      </div>
    )
  })

  const UserDropdown = props => {
    const dispatch = useDispatch();
    return (
      <>
        <DropdownMenu >
          <DropdownItem onClick={() => {
            activateAuth(null)
            sessionStorage.clear();
            dispatch({
              type: PURGE,
              key: 'root', // Whatever you chose for the "key" value when initialising redux-persist in the **persistCombineReducers** method - e.g. "root"
              result: () => null // Func expected on the submitted action.
            });
          }}>
            <Icon.Power size={14} color={'#626262'} className="mr-50" />
            <span style={{ color: '#626262' }} className="align-middle">Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </>
    )
  }

  return(
    <div className="float-right">
      <ul className="nav navbar-nav navbar-nav-user">
          <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
            <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Icon.Bell size={21} />
              <Badge pill color="primary" className="badge-up">
                {" "}
                {totalNotifications}{" "}
              </Badge>
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-menu-media">
              <li className="dropdown-menu-header">
                <div className="dropdown-header mt-0">
                  <h3 className="text-white">{totalNotifications}</h3>
                  <span className="notification-title">Notifications</span>
                </div>
              </li>
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative"
                options={{
                  wheelPropagation: false
                }}
              >
                {renderNotificationItems}
              </PerfectScrollbar>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600">
                  {`${user.name}`}
                </span>
                <span className="user-status">
                {
                    user.profile === 7 ? 'Administrator' :
                      user.profile === 104 ? 'Supervisor' :
                        user.profile === 106 ? 'Support' :
                          user.profile === 118 ? 'Agent' :
                            user.profile === 211 ? 'Processor' :
                              user.profile === 219 ? 'Customer Leader' :
                                user.profile === 227 ? 'Customer Service' :
                                  user.profile === 228 ? 'Supplementary Processor' :
                                    user.profile === 238 ? 'Quality Leader' :
                                      user.profile === 239 ? 'Quality Aux' :
                                        user.profile === 251 ? 'Administrator TOP' :
                                          user.profile === 255 ? 'Auditor' :
                                            user.profile === 256 ? 'Recovery Master' :
                                              user.profile === 265 ? 'NPN' :
                                                user.profile === 275 ? 'Consultant' :
                                    ''
                  }
                </span>
              </div>
              <span data-tour="user">
                <img
                  src={user.photo ? `${URL_IMG}/${user.photo}` : imgDefault}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              </span>
            </DropdownToggle>
            <UserDropdown />
        </UncontrolledDropdown>
      </ul>
      <ToastContainer />
    </div>
  )
}
export default NavbarUser

