import React, {useState} from "react"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import { FaRegListAlt, FaAward, FaColumns, FaEnvelope } from "react-icons/fa"
import { ModalNotePad } from "../../../views/components/modal/ModalNotePad"
import { ModalPremium } from "../../../views/components/modal/ModalPremium"
import { ModalNoteColumns } from "../../../views/components/modal/ModalNoteColumns"

let size = 20

const ThemeNavbar = props => {
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]

  const [showModalNotePad, setShowModalNotePad] = useState(false)
  const [showModalPremium, setShowModalPremium] = useState(false)
  const [showModalNoteColumns, setShowModalNoteColumns] = useState(false)

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <FaAward title="premium" size={size} style={{cursor:'pointer'}} onClick={() => { setShowModalPremium(!showModalPremium) }} />
                {"  "}
                <FaRegListAlt title="My notes" size={size} style={{cursor:'pointer'}} onClick={() => { setShowModalNotePad(!showModalNotePad) }} />
                {"  "}
                <FaColumns title="My notes columns" size={size} style={{cursor:'pointer'}} onClick={() => { setShowModalNoteColumns(!showModalNoteColumns) }} />
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName="John Doe"
                userImg={ userImg }
              />
            </div>
          </div>
        </div>
        {
          <>
          { showModalNotePad && <ModalNotePad showModal={showModalNotePad} setShowModal={setShowModalNotePad}  />}
          { showModalPremium && <ModalPremium showModal={showModalPremium} setShowModal={setShowModalPremium}  />}
          { showModalNoteColumns && <ModalNoteColumns showModal={showModalNoteColumns} setShowModal={setShowModalNoteColumns}  />}
          </>
        }
      </Navbar>
    </React.Fragment>
  )
}


export default ThemeNavbar
